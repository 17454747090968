import request from "@/utils/request";
const url = "https://huanxinxiang.ai-me.cn";
import { setAppName } from "@/utils/common";
const appName = setAppName();
// 支付宝支付
export const alipayNoSignPay = (data, ipBaseURL = url) => request({
  url: '/likeu-service/v1/alipay-no-sign-pay/',
  method: 'post',
  headers: {
    "App-Name": 'WebPay',
  },
  ipBaseURL,
  data,
});

// 发送短信
export const code = (data) => request({
  url: '/huanxinxiang-service/v1/code/',
  method: 'post',
  headers: {
    "App-Name": appName,
  },
  ipBaseURL: url,
  data,
});

// 支付宝签约
export const codeDlipayPay = (data) => request({
  url: '/huanxinxiang-service/v1/alipay-sms-pay/',
  method: 'post',
  headers: {
    "App-Name": appName,
  },
  ipBaseURL: url,
  data,
});