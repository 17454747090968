<template>
  <van-popup :close-on-click-overlay="false" v-model="visibility" class="dialog-alert">
    <img
      class="close"
      @click="close"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/yilucamera/close.png"
    />
    <div class="title">
      {{ title }}
    </div>
    <div class="cont">{{ message }}</div>
    <div class="footer">
      <div class="submit" @click="close">我知道了</div>
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    visibility: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    message: { type: String, default: "" },
  },
  methods: {
    close() {
      this.$emit("update:visibility", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-alert {
  border-radius: 18px;
  width: 658px;
  padding-bottom: 42px;
  background-image: linear-gradient(220deg, #292722 9.08%, #131312 74%);
  stroke-width: 1px;
  stroke: #292624;
  // position: relative;

  .close {
    position: absolute;
    right: 23px;
    top: 33px;
    width: 41px;
    height: 42px;
  }

  .title {
    color: #fff;
    text-align: justify;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 35px 0 21px 0;
    text-align: center;
  }

  .cont {
    color: #fff;
    padding: 0 44px 0 46px;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: pre-wrap;
    max-height: 640px;
    overflow: auto;
  }
  .footer {
    color: #ffb21b;
    text-align: center;
    padding: 35px 0 23px 0;
  }
}
</style>
